import { Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenuToggle, NavbarMenu, NavbarMenuItem, Link, Button, Avatar } from "@nextui-org/react";
import { useState } from "react";
import logo from "../../../public/peacepal_logo.png";
import StreakCounter from "./StreakCounter";

const NavBar = () => {
  return (
    <Navbar 
      className="bg-[#191b29] h-24 min-h-24 fixed top-0 w-full"
      maxWidth="full"
    >
      <NavbarContent className="pl-4 gap-0" justify="start">
        <NavbarBrand className="w-full max-w-[200px]">
          <img 
            src={logo} 
            alt="PeacePal Logo" 
            className="pt-4 h-32 md:h-56 w-auto"
          />
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent justify="end" className="pr-4">
        <div className="hidden md:flex">
        <NavbarItem>
          <Button 
            className="bg-purple-500 text-white px-6 py-2 transition-all duration-300 ease-in-out shadow-[0_0_10px_2px_rgba(168,85,247,0.4)] hover:shadow-[0_0_15px_3px_rgba(168,85,247,0.6)]" 
            href="#" 
            variant="flat" 
            radius="full"
          >
            UPGRADE
          </Button>
        </NavbarItem>
        <NavbarItem>
          <StreakCounter />
        </NavbarItem>
        <NavbarItem>
          <Avatar 
            icon={<span className="text-2xl">👤</span>}
            classNames={{
              base: "bg-gradient-to-br from-[#333] to-[#555]",
              icon: "text-white/90",
            }}
          />
        </NavbarItem>
        </div>
      </NavbarContent>
    </Navbar>
  );
}

export default NavBar;