import React, { useEffect, useState } from 'react';
import { authenticatedRequest } from '../../api';
// import { Flame } from 'lucide-react';

const StreakCounter = () => {
  const [streakData, setStreakData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchStreakData = async () => {
    try {
      const userIdResponse = await authenticatedRequest.get('/auth/get-user-id');
      const userId = userIdResponse.data.userId;
      
      const response = await authenticatedRequest.get(
        `https://peacepal-gogettr-c2775e5dd645.herokuapp.com/users/${userId}/streak`
      );
      setStreakData(response.data);
    } catch (error) {
      console.error('Error fetching streak:', error);
    } finally {
      setLoading(false);
    }
  };

  const checkIn = async () => {
    try {
      const userIdResponse = await authenticatedRequest.get('/auth/get-user-id');
      const userId = userIdResponse.data.userId;
      
      await authenticatedRequest.post(
        `https://peacepal-gogettr-c2775e5dd645.herokuapp.com/users/${userId}/streak/check-in`
      );
      
      // Refresh streak data
      fetchStreakData();
    } catch (error) {
      console.error('Error updating streak:', error);
    }
  };

  useEffect(() => {
    fetchStreakData();
    
    // Check for streak automatically when component mounts
    checkIn();
  }, []);

  if (loading) return null;

  return (
    <div className="flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-orange-500 to-red-500 rounded-full">
      {/* <Flame className="text-white" size={20} /> */}
      <span className="text-white font-bold">
      🔥 {streakData?.currentStreak || 0}
      </span>
    </div>
  );
};

export default StreakCounter;